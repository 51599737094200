import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100%;
    margin: 0px;
    padding: 0px;
    min-height: 100vh;
  }

  html {
    height: 100%;
    box-sizing: border-box;
    scrollbar-color:#4e5057 #1b1c23;
    scrollbar-width: thin!important;

    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #33363c;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    touch-action: manipulation;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 14px;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: inherit;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a, button {
		text-decoration: none;
    color: initial;
    cursor: pointer;
    outline: none;
  }

  button {
    border: none;
  }

  ol, ul {
    list-style: none;
  }
`