import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  initialScreenShowed: false,
}

const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    closeInitialScreen: (state) => {
      state.initialScreenShowed = true
    },
  },
})

const { actions: appActions, reducer: appReducer } = app

export { appActions, appReducer }
