import React, {
  useEffect, useState 
} from 'react'

import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import i18n from 'src/localization'
import { GAMES } from 'src/api/games'

const Container = styled.div`
  margin-top: 0;
  flex: 1;
  padding: 0 24px;
  background-color: #F5F8FA;
  padding-top: 24px;
  padding-bottom: 50px;
`

const Title = styled.h1`
  font-size: 21px;
  color: #4A6495;
  font-family: 'Rubik-Bold', sans-serif;
  margin-bottom: 16px;
`

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  max-width: 420px;
  @media (min-width: 1200px) {
    max-width: 1200px;
    width: 100%;
  }
`

const Card = styled.div`
  margin-bottom: 16px;
  width: 47%;
  max-width: 200px;
  background-color: white;
  padding: 16px 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  @media (min-width: 1200px) {
    width: 180px;
  }
`

const CardTitle = styled.h2`
  font-size: 16px;
  color: #4A6495;
  font-family: 'Rubik-Bold', sans-serif;
  text-transform: uppercase;
  text-align: center;
`

const Image = styled.img`
  margin-top: 20px;
  width: 100px;
  height: 100px;
  object-fit: contain;
`

const PlaceholderCard = styled(Card)`
  background-color: #EFF1F7;
`

const PlaceholderRow1 = styled.div`
  width: 120px;
  height: 11px;
  background-color: #EFF1F7;
  border-radius: 20px;
`

const PlaceholderRow2 = styled.div`
  margin-top: 20px;
  width: 100px;
  height: 100px;
  background-color: #EFF1F7;
`

const CardPlaceholder = () => (
  <PlaceholderCard>
    <PlaceholderRow1 />
    <PlaceholderRow2 />
  </PlaceholderCard>
)

export function CategoriesScreen() {
  const navigate = useNavigate()
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await GAMES.getCategories()
        setCategories(response.data)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching categories:', error)
        setLoading(false)
      }
    }

    fetchCategories()
  }, [])

  const handlePress = (category) => {
    navigate(`/categories/${category.id}`, { state: { categoryName: category.attributes.name } })
  }

  return (
    <Container>
      <Title>{i18n.t('categoriesScreen_title')}</Title>
      <Cards>
        {loading &&
          Array.from({ length: 6 }).map((_, index) => (
            <CardPlaceholder key={index} />
          ))}

        {!loading && categories.map(category => (
          <Card
            key={category.id}
            onClick={() => handlePress(category)}
          >
            <CardTitle>{category.attributes.name}</CardTitle>
            <Image
              src={category.attributes.image}
              alt={category.attributes.name}
            />
          </Card>
        ))}
      </Cards>
    </Container>
  )
}