import React from 'react'

import { i18n } from 'src/localization'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding: 24px 24px 50px;
  min-height: 100vh;
  background-color: #F5F8FA;
`

const TopRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
`

const QuestionNumber = styled.h2`
  font-family: 'Rubik-Bold', sans-serif;
  font-size: 16px;
  color: #4a6495;
  text-align: center;
  @media (min-width: 1200px) {
    font-size: 20px;
  }
`

const QuestionsCount = styled.span`
  font-family: 'Rubik-Medium', sans-serif;
  color: #d2d5dd;
`

const ChildrenContainer = styled.div`
  width: 100%;
`

export const RoundLayout = ({
  children, questionNumber, questionsCount
}) => {
  return (
    <Container>
      <TopRow>
        <QuestionNumber>
          {i18n.t('gameScreen_question')} {questionNumber}

          <QuestionsCount>
/
            {questionsCount}
          </QuestionsCount>
        </QuestionNumber>
      </TopRow>

      <ChildrenContainer>
        {children}
      </ChildrenContainer>
    </Container>
  )
}