export const en = {
  gamesScreen_title: 'List of games',
  gamesScreen_description: 'Games you can play',
  gamesScreen_all: 'All',
  gamesScreen_popular: 'Popular',
  gamesScreen_new: 'New',

  gameCard_play: 'Play',
  gameCard_questions: 'questions',
  gameCard_completed: 'Game completed',
  gameCard_from: 'out of',
  gameCard_points: 'points',

  gameScreen_question: 'Question',
  gameScreen_correct: 'Correct answer',
  gameScreen_next: 'Next',
  gameScreen_end: 'End of the game!',
  gameScreen_scored: 'In this game you scored',
  gameScreen_points: 'points',

  ratingScreen_players: 'Players',
  ratingScreen_points: 'Points',
  ratingScreen_emptyTitle: 'Rating',
  ratingScreen_emptyDescription: 'The rating will be available as soon as the game statistics are collected.',

  menu_unauthorized: 'Login failed',
  menu_games: 'Games',
  menu_aboutApp: 'About the application',
  menu_removeAccount: 'Delete account',
  menu_logout: 'Sign out',
  menu_removingTitle: 'Account Deletion',
  menu_removingDescription: 'Are you sure you want to delete the account?',
  menu_removingCancel: 'Cancel',
  menu_removingRemove: 'Delete',

  categoriesScreen_title: 'Categories',

  helloScreen_start: 'GET STARTED!',
  helloScreen_ownedBy: 'Quiz is owned by',

  aboutAppScreen_email: 'Email: ',

  header_login: 'Log in',

  loginScreen_title: 'Have we met?',
  loginScreen_description: 'Choose from two options',
  loginScreen_option1: 'log in',
  loginScreen_option2: 'sign up',

  loginScreen_letsCheck: `Let's check?`,
  loginScreen_wrong: 'Take your time :)',
  loginScreen_error: 'Error',
  loginScreen_sent: 'We have sent',
  loginScreen_emailError: 'You must enter your valid emaill',
  loginScreen_enterEmail: 'Enter email',
  loginScreen_signIn: 'Sign in',
  loginScreen_enterPassword: 'Enter password.',
  loginScreen_forgot: 'Forgot?',
  loginScreen_restore: 'Restore',
  loginScreen_check: 'Next',
  loginScreen_passwordError: 'Please enter a password at least 6 characters long',
  loginScreen_wrongPassword: 'Wrong password',
  loginScreen_welcomeTitle: 'Welcome!',
  loginScreen_welcomeDescription: 'Enjoy the game, and we will test your knowledge. Get points for playing and compete with other players.',
  loginScreen_welcomeBtn: 'Mutually',
  loginScreen_enterEmailForRestore: 'Enter email',
  loginScreen_sendEmail: 'Send Email',
  loginScreen_codeSent: 'We have sent',
  loginScreen_codeDescription: 'The email has been sent, check the mailbox. Enter the 6-digit code',
  loginScreen_wrongCode: 'Wrong code',
  loginScreen_createPassword: 'Create a password',
  loginScreen_next: 'Next',

  loginScreen_step: 'Step',
  loginScreen_nameStepTitle: `Let's get acquainted?`,
  loginScreen_nameStepEnterName: 'Enter your name',
  loginScreen_nameStepName: 'Name',
  loginScreen_nicknameDescription: 'Now a little creativity, the nickname can be funny :)',
  loginScreen_nicknameExists: 'A player with this nickname already exists',
  loginScreen_enterNickname: 'Enter nickname',
  loginScreen_nickname: 'Nickname',
  loginScreen_registrationEmail: 'Enter your email address. We will send a confirmation code to complete the registration',
  loginScreen_checkPassword: 'Check that the password entered is correct',
  loginScreen_weSent: 'We sent',
  loginScreen_enterCode: 'Enter a 6-digit code to complete registration.',
  loginScreen_codeNotReceived: 'Code not received? ',
  loginScreen_checkSpam: 'Please check the spam section',
  loginScreen_orResend: 'or resend the code',
  loginScreen_again: '',
  loginScreen_registrationSuccessTitle: 'You did it!',
  loginScreen_registrationSuccessDescription: 'Have a nice game, and we will test your knowledge. Get points for the game and compete with other players',
  loginScreen_registrationSuccessBtn: 'Great',
  loginScreen_auth_with_social: 'Or authorize through one of the services'
}