import React, {
  useEffect, useState, useCallback 
} from 'react'

import styled from 'styled-components'
import {
  useDispatch, useSelector 
} from 'react-redux'
import {
  useNavigate, useParams, useLocation 
} from 'react-router-dom'
import { GAMES } from 'src/api/games'
import i18n from 'src/localization'
import { userActions } from 'src/redux/slices/user'
import { GameCard } from 'src/components/GameCard'
import { debounce } from 'lodash'

const Container = styled.div`
  flex: 1;
  background-color: #F5F8FA;
  padding: 24px 24px 50px;
`

const ScrollView = styled.div`
  flex: 1;
  overflow-y: auto;
`

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  overflow-x: auto;
`

const Tab = styled.button`
  margin-right: 12px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  background-color: ${props => props.active ? '#7581A2' : '#E5E8EF'};
  border: 1px solid #7581A2;
  border-radius: 24px;
  font-family: 'Rubik-Bold', sans-serif;
  font-size: 16px;
  color: ${props => props.active ? 'white' : '#7581A2'};
  cursor: pointer;
`

const EmptyContainer = styled.div`
  margin-top: 100px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const EmptyTitle = styled.h2`
  width: 242px;
  text-align: center;
  font-family: 'Rubik-Medium', sans-serif;
  color: #4a6495;
  font-size: 21px;
  @media (min-width: 1200px) {
    font-size: 25px;
  }
`

const EmptyDescription = styled.p`
  margin-top: 12px;
  width: 242px;
  text-align: center;
  font-family: 'Rubik-Medium', sans-serif;
  color: #d2d5dd;
  font-size: 12px;
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`

const CardPlaceholder = styled.div`
  margin-bottom: 24px;
  width: 100%;
  height: 138px;
  padding: 22px 21px;
  background-color: #D2D5DD;
  border-radius: 20px;
`

const PlaceholderRow = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  background-color: #EFF1F7;
  border-radius: 20px;
  margin-top: ${props => props.marginTop || 0};
`

const TitleStyled = styled.h1`
  font-size: 24px;
  font-family: 'Rubik-Bold', sans-serif;
  color: #4A6495;
  margin-bottom: 8px;
`

const Title = ({ text }) => <TitleStyled>{text}</TitleStyled>

const DescriptionStyled = styled.p`
  font-size: 14px;
  font-family: 'Rubik-Regular', sans-serif;
  color: #7581A2;
  margin-bottom: 16px;
`

const Description = ({
  text, style 
}) => <DescriptionStyled style={style}>{text}</DescriptionStyled>

export const MyGamesScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id: categoryId } = useParams()
  const location = useLocation()
  const categoryName = location.state?.categoryName

  const [state, setState] = useState({
    games: [],
    refreshing: true,
    empty: false,
    loading: true,
    page: 0
  })

  const [activeTab, setActiveTab] = useState('all')

  const gamesStates = useSelector(state => state.games.gamesStates)

  const getGames = async (refresh = false) => {
    if (state.empty && !refresh) {
      return
    }

    setState(prev => ({
      ...prev, loading: true 
    }))

    const newPage = refresh ? 0 : state.page + 1

    const params = {
      'pagination[limit]': 14,
      'pagination[start]': newPage * 14
    }

    if (activeTab !== 'all') {
      params.sort = activeTab
    }

    if (categoryId) {
      params.category = categoryId
    }

    try {
      const response = await GAMES.getList(params)

      if (response?.length) {
        setState(prev => ({
          games: refresh ? response : [...prev.games, ...response],
          refreshing: false,
          empty: false,
          loading: false,
          page: newPage
        }))
      } else {
        setState(prev => ({
          games: refresh ? [] : prev.games,
          refreshing: false,
          empty: true,
          loading: false,
          page: newPage
        }))
      }
    } catch (error) {
      if (error?.error?.status === 401) {
        localStorage.clear()
        dispatch(userActions.logout())
      }
    }
  }

  useEffect(() => {
    getGames(true)
  }, [activeTab, categoryId])

  const handleLoadMore = useCallback(debounce(() => {
    if (!state.loading && !state.empty) {
      getGames(false)
    }
  }, 1000), [state.loading, state.empty])

  useEffect(() => {
    const handleScroll = () => {
      const element = document.documentElement
      const needLoadMore = Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < 400

      if (needLoadMore) {
        handleLoadMore()
      }
    }

    document.addEventListener('scroll', handleScroll)

    return () => document.removeEventListener('scroll', handleScroll)
  }, [handleLoadMore])

  const handlePlayPress = (game) => {
    navigate(`/game/${game.id}`, { state: game })
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab)
  }

  return (
    <Container>
      <ScrollView >
        <Title text={categoryName || i18n.t('gamesScreen_title')} />
        <Description
          text={i18n.t('gamesScreen_description')}
          style={{
            marginTop: 8, marginBottom: 16 
          }}
        />

        <TabsContainer>
          <Tab
            active={activeTab === 'all'}
            onClick={() => handleTabChange('all')}
          >
            {i18n.t('gamesScreen_all')}
          </Tab>
          <Tab
            active={activeTab === 'popular'}
            onClick={() => handleTabChange('popular')}
          >
            {i18n.t('gamesScreen_popular')}
          </Tab>
          <Tab
            active={activeTab === 'new'}
            onClick={() => handleTabChange('new')}
          >
            {i18n.t('gamesScreen_new')}
          </Tab>
        </TabsContainer>

        {state.loading && Array.from({ length: 6 }).map((_, index) => (
          <CardPlaceholder key={index}>
            <PlaceholderRow
              width="120px"
              height="11px"
            />
            <PlaceholderRow
              width="256px"
              height="7px"
              marginTop="21px"
            />
            <PlaceholderRow
              width="256px"
              height="7px"
              marginTop="9px"
            />
            <div style={{
              marginTop: 15, display: 'flex', justifyContent: 'space-between', alignItems: 'center' 
            }}
            >
              <PlaceholderRow
                width="88px"
                height="7px"
              />
              <PlaceholderRow
                width="128px"
                height="30px"
              />
            </div>
          </CardPlaceholder>
        ))}

        {state.games.length > 0 && state.games.map((game, index) => (
          <GameCard
            key={index}
            onPress={() => handlePlayPress(game)}
            showPlay
            {...game}
            {...game.attributes}
            gameState={gamesStates?.[game.id]}
          />
        ))}

        {state.empty && !state.games.length && (
          <EmptyContainer>
            <EmptyTitle>{i18n.t('gamesScreen_emptyTitle')}</EmptyTitle>
            <EmptyDescription>{i18n.t('gamesScreen_emptyDescription')}</EmptyDescription>
          </EmptyContainer>
        )}
      </ScrollView>
    </Container>
  )
}
