import React, {
  useEffect, useState 
} from 'react'

import styled from 'styled-components'
import { GAMES } from 'src/api/games'
import i18n from 'src/localization'

import goldImg from '../images/gold.png'
import silverImg from '../images/silver.png'
import bronzeImg from '../images/bronze.png'

const Container = styled.div`
  flex: 1;
`

const Content = styled.div`
  margin-top: 19px;
  margin-bottom: 17px;
  flex: 1;
  border-radius: 20px;
  background-color: white;
`

const Header = styled.div`
  margin-top: 20px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`

const HeaderTeams = styled.div`
  margin-left: 10%;
  width: 70%;
  font-family: 'Rubik-Bold', sans-serif;
  opacity: 0.26;
  color: #4a6495;
  font-size: 13px;
`

const HeaderPoints = styled.div`
  font-family: 'Rubik-Bold', sans-serif;
  opacity: 0.26;
  color: #4a6495;
  font-size: 13px;
  width: 20%;
  text-align: center;
`

const RatingRow = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  height: 61px;
  align-items: center;
  position: relative;
  margin: 0 24px;
  @media (max-width: 768px) {
    margin: 0 10px;
  }
  ${props => props.gold && `
    border-radius: 20px;
    background-color: #fbd43a71;
  `}
  ${props => props.silver && `
    border-radius: 20px;
    background-color: #f4f5f9;
  `}
  ${props => props.bronze && `
    border-radius: 20px;
    background-color: #ffe4cf;
  `}
`

const Place = styled.div`
  width: 10%;
  font-size: 9px;
  color: #acb4c3;
  font-family: 'Rubik-Bold', sans-serif;
  text-align: center;
  ${props => props.gold && 'color: #fcbd11;'}
`

const Team = styled.div`
  width: 70%;
  font-family: 'Rubik-Bold', sans-serif;
  font-size: 14px;
  color: #acb4c3;
  ${props => props.gold && 'color: #fcbd11;'}
  ${props => props.silver && 'color: #c3c4cb;'}
  ${props => props.bronze && 'color: #d0a18f;'}
`

const Points = styled.div`
  width: 20%;
  font-family: 'Rubik-Bold', sans-serif;
  color: #acb4c3;
  text-align: center;
  ${props => (props.gold || props.silver || props.bronze) && 'color: #5c5c5c;'}
`

const Medal = styled.img`
  position: absolute;
  right: -10px;
  width: 20px;
  height: 20px;
`

const EmptyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const EmptyTitle = styled.div`
  width: 242px;
  text-align: center;
  font-family: 'Rubik-Medium', sans-serif;
  color: #4a6495;
  font-size: 21px;
`

const EmptyDescription = styled.div`
  margin-top: 12px;
  width: 242px;
  text-align: center;
  font-family: 'Rubik-Medium', sans-serif;
  color: #d2d5dd;
  font-size: 12px;
`

const RatingRowComponent = ({
  place, userName, points 
}) => {
  const firstPlace = place === 1
  const secondPlace = place === 2
  const thirdPlace = place === 3

  return (
    <RatingRow
      gold={firstPlace}
      silver={secondPlace}
      bronze={thirdPlace}
    >
      <Place gold={firstPlace}>
        {!firstPlace && !secondPlace && !thirdPlace && place}
      </Place>
      <Team
        gold={firstPlace}
        silver={secondPlace}
        bronze={thirdPlace}
      >
        {userName}
      </Team>
      <Points
        gold={firstPlace}
        silver={secondPlace}
        bronze={thirdPlace}
      >
        {points}
      </Points>
      {firstPlace && (
        <Medal
          src={goldImg}
          alt="Gold"
        />)
      }
      {secondPlace && <Medal
        src={silverImg}
        alt="Silver"
                      />}
      {thirdPlace && <Medal
        src={bronzeImg}
        alt="Bronze"
                     />}
    </RatingRow>
  )
}

const HeaderComponent = () => (
  <Header>
    <HeaderTeams>{i18n.t('ratingScreen_players')}</HeaderTeams>
    <HeaderPoints>{i18n.t('ratingScreen_points')}</HeaderPoints>
  </Header>
)

export const RatingScreen = () => {
  const [state, setState] = useState({
    rating: [],
    empty: false,
    refreshing: true,
  })

  const getRating = async () => {
    const response = await GAMES.getRating()
    if (response.data.length) {
      setState({
        rating: response.data, refreshing: false, empty: false 
      })
    } else {
      setState({
        rating: [], refreshing: false, empty: true 
      })
    }
  }

  useEffect(() => {
    getRating()
  }, [])

  return (
    <Container>
      <Content>
        <HeaderComponent />
        {state.empty ? (
          <EmptyContainer>
            <EmptyTitle>{i18n.t('ratingScreen_emptyTitle')}</EmptyTitle>
            <EmptyDescription>{i18n.t('ratingScreen_emptyDescription')}</EmptyDescription>
          </EmptyContainer>
        ) : (
          state.rating.map((item, index) => (
            <RatingRowComponent
              key={item._id}
              place={index + 1}
              points={item.attributes.totalResults}
              userName={item.attributes.user.data.attributes.username}
            />
          ))
        )}
      </Content>
    </Container>
  )
}