import React, {
  useEffect, useState 
} from 'react'

import classNames from 'classnames'
import styled from 'styled-components'

const Container = styled.div`
  margin-top: 16px;
  width: 100%;
`

const Button = styled.button`
  margin-bottom: 10px;
  height: 48px;
  width: 100%;
  border: none;
  border-radius: 30px;
  font-family: 'Rubik-Bold', sans-serif;
  font-size: 11px;
  color: white;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? 0.5 : 1};
  background-color: #FBC399;
  color: #B67C62;
  box-shadow: 0 2px 4px #B67C62;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  text-align: center;

  &.green {
    background-color: #90C67B;
    color: white;
    box-shadow: 0 2px 4px #75AD5F;
  }

  &.red {
    background-color: #FF5550;
    color: white;
    box-shadow: 0 2px 4px #EE413C;
  }

  &.disabled {
    background-color: #EAEBF0;
    color: #A7ABB5;
    pointer-events: none;
    box-shadow: 0 2px 4px #D5D6D9;
  }
`

export const AnswerOptions = ({
  options, onSetAnswer, infoModalOpened
}) => {
  const [answer, setAnswer] = useState(null)

  const handleSetAnswer = (answer) => {
    setAnswer(answer)

    setTimeout(() => {
      setAnswer(null)
    }, 3000)

    onSetAnswer(answer)
  }

  useEffect(() => {
    if (infoModalOpened) {
      setAnswer(null)
    }
  }, [infoModalOpened])

  return (
    <Container>
      {options.map((option) => (
        <Button
          key={option.id}
          onClick={() => handleSetAnswer(option)}
          color={!answer ? 'orange' : (answer.isCorrect || option.isCorrect) ? 'green' : 'red'}
          disabled={!!answer && answer.text !== option.text && !option.isCorrect}
          className={classNames([!answer ? 'orange' : (answer.isCorrect || option.isCorrect) ? 'green' : 'red', { disabled: !!answer && answer.text !== option.text && !option.isCorrect }])}
        >
          {option.text}
        </Button>
      ))}
    </Container>
  )
}