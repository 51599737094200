import React from 'react'

import styled from 'styled-components'

const Container = styled.div`
  margin-top: 16px;
  background-color: white;
  border-radius: 20px;
  padding: 20px 17px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1200px) {
    flex-direction: row-reverse;
    align-items: center;
    padding: 20px 30px;
    min-height: 100px;
  }
`

const Text = styled.p`
  font-family: 'Rubik-Medium', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #4a6495;
  width: 100%;

  @media (min-width: 1200px) {
    font-size: 24px;
    width: ${props => props.hasImage ? '80%' : '100%'};
  }
`

const Image = styled.img`
  margin-bottom: 10px;
  width: 100%;
  height: 195px;
  object-fit: contain;

  @media (min-width: 1200px) {
    width: 20%;
    margin-bottom: 0;
    margin-right: 20px;
  }
`

export const Question = ({
  text, image 
}) => {
  return (
    <Container>
      {image && image[0] && (
        <Image
          src={image}
          alt="Question"
        />
      )}

      <Text hasImage={!!image && !!image[0]}>
        {text}
      </Text>
    </Container>
  )
}