import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userData: {
    name: '',
    username: '',
    email: '',
  },
}

const app = createSlice({
  name: 'userRegistration',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state.userData[key] = value
      })
    },
  },
})

const { actions: userRegistrationActions, reducer: userRegistrationReducer } = app

export { userRegistrationActions, userRegistrationReducer }
