import React from 'react'

import i18n from 'src/localization'
import styled from 'styled-components'


const Container = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 17px 24px;
  margin-bottom: 16px;
  @media (max-width: 768px) {
    padding: 17px 10px;
  }
`

const RightContainer = styled.div`
  flex: 1;
`

const Title = styled.h2`
  font-family: 'Rubik-Bold', sans-serif;
  font-size: 16px;
  color: #4a6495;
  margin: 0;
`

const Description = styled.p`
  margin: 9px 0;
  font-family: 'Rubik-Regular', sans-serif;
  font-size: 12px;
  color: #4a6495;
`

const PlayWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DateColumn = styled.div``

const QuestionsText = styled.span`
  font-family: 'Rubik-Bold', sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: #4A6495;
  font-weight: 700;
`

const GameCompletedText = styled.span`
  font-family: 'Rubik-Bold', sans-serif;
  color: #E95847;
  font-weight: 700;
`

const GamePointsText = styled.span`
  font-family: 'Rubik-Bold', sans-serif;
  margin-top: 8px;
  color: #FAC945;
  display: block;
  font-weight: 700;
`

const PlayButton = styled.button`
  width: 100px;
  height: 30px;
  background-color: #FBC399;
  color: #B67C62;
  border: none;
  border-radius: 16px;
  font-family: 'Rubik-Bold', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px #B67C62;
  text-transform: uppercase;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export const GameCard = ({
  name,
  description,
  questionsCount,
  onPress,
  isMyGame,
  showPlay,
  gameState = {}
}) => (
  <Container>
    <RightContainer>
      <Title>{name}</Title>
      <Description>{description}</Description>
      <PlayWrapper>
        {!gameState.finished && (
          <DateColumn>
            <QuestionsText>
              {!!gameState.currentQuestionIndex && `${gameState.currentQuestionIndex}/`}
              {questionsCount} {i18n.t('gameCard_questions')}
            </QuestionsText>
          </DateColumn>
        )}
        {!!gameState.finished && (
          <DateColumn>
            <GameCompletedText>
              {i18n.t('gameCard_completed')}
            </GameCompletedText>
            <GamePointsText>
              {gameState.rightAnswersCount} {i18n.t('gameCard_from')} {questionsCount} {i18n.t('gameCard_points')}
            </GamePointsText>
          </DateColumn>
        )}
        {showPlay && (
          <PlayButton
            onClick={onPress}
            disabled={!!gameState.finished}
          >
            {i18n.t('gameCard_play')}
          </PlayButton>
        )}
      </PlayWrapper>
    </RightContainer>
  </Container>
)