import React from 'react'

import styled from 'styled-components'

const ScreenWrapper = styled.div`
  flex: 1;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`

const Container = styled.div`
  background-color: white;
  border-radius: 20px;
  padding: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Menu = styled.div`
  width: 24px;
  height: 20px;
  background-color: #D2D5DD;
  border-radius: 4px;
`

const Content = styled.div`
  margin-top: 28px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.div`
  margin-bottom: 19px;
  width: 120px;
  height: 9px;
  background-color: #D2D5DD;
  border-radius: 4px;
`

const Card = styled.div`
  width: 100%;
  height: 290px;
  background-color: #D2D5DD;
  border-radius: 20px;
  padding: 22px 16px;
  display: flex;
  flex-direction: column;
`

const CardLine = styled.div`
  width: 100%;
  height: 9px;
  background-color: #EFF1F7;
  border-radius: 4px;
  margin-bottom: 11px;
`

const Answer = styled.div`
  width: 100%;
  height: 52px;
  background-color: #D2D5DD;
  border-radius: 20px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const AnswerInner = styled.div`
  width: 143px;
  height: 12px;
  background-color: #EFF1F7;
  border-radius: 4px;
`

export const RoundPreview = () => {
  return (
    <ScreenWrapper>
      <Container>
        <Menu />

        <Content>
          <Title />

          <Card>
            {[...Array(10)].map((_, index) => (
              <CardLine key={index} />
            ))}
          </Card>
        </Content>

        {[...Array(4)].map((_, index) => (
          <Answer key={index}>
            <AnswerInner />
          </Answer>
        ))}
      </Container>
    </ScreenWrapper>
  )
}