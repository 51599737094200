import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userData: {
    userId: '',
    name: '',
    phone: '',
    teamName: '',
    teamId: '',
    shortTeamId: '',
    email: '',
    role: '',
    jwt: '',
  },
  isAuthorized: false,
}

const app = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state.userData[key] = value
      })
    },
    clearUserData: (state) => {
      state.userData = {}
    },
    login: (state, action) => {
      state.userData = action.payload
      state.isAuthorized = true
    },
    logout: (state) => {
      state.isAuthorized = false
      state.userData = {}
    },
  },
})

const { actions: userActions, reducer: userReducer } = app

export { userActions, userReducer }
