import {
  createSlice, createAsyncThunk 
} from '@reduxjs/toolkit'
import { GAMES } from 'src/api/games'

// Асинхронный thunk для загрузки категорий
export const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await GAMES.getCategories()
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    items: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.items = action.payload
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })
  },
})

// Экспорт селекторов
export const selectAllCategories = (state) => state.categories.items
export const selectCategoriesStatus = (state) => state.categories.status
export const selectCategoriesError = (state) => state.categories.error

// Экспорт редьюсера
export default categoriesSlice.reducer

export const categoriesReducer = categoriesSlice.reducer

// Экспорт действий
export const categoriesActions = {
  fetchCategories,
  // Здесь могут быть другие действия, если они нужны
}