import React, { useEffect } from 'react'

import {
  BrowserRouter as Router, Route,
  Routes
} from 'react-router-dom'
import {
  Provider, useDispatch 
} from 'react-redux'
import styled from 'styled-components'

import { store } from './redux/store'
import Header from './components/Header'
import { MyGamesScreen } from './pages/MyGamesScreen'
import { CategoriesScreen } from './pages/CategoriesScreen'
import { RatingScreen } from './pages/RatingScreen'
import TabBar from './components/BottomTabbar'
import { GlobalStyle } from './styled.global'
import { Round } from './components/Round'
import { USER } from './api/user'
import { userActions } from './redux/slices/user'

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const ContentContainer = styled.div`
  flex: 1;
  padding-bottom: 56px;
`

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <AppContainer>
          {/* <Header /> */}
          <Routes>
            <Route
              index
              path="/"
              element={<MyGamesScreen />}
            />
            <Route
              path="categories"
              element={<CategoriesScreen />}
            />
            <Route
              path="categories/:id"
              element={<MyGamesScreen />}
            />
            <Route
              path="/rating"
              element={<RatingScreen />}
            />
            <Route
              path="/game/:id"
              element={<Round />}
            />
          </Routes>

          <TabBar />
        </AppContainer>
      </Router>

      <GlobalStyle />
    </Provider>
  )
}

const WithAuthorization = ({ children }) => {
  const dispatch = useDispatch()

  const [authorized, setAuthorized] = React.useState(false)

  useEffect(async () => {
    const initData = window.Telegram.WebApp.initData

    const response = await USER.authUser({ initData })

    localStorage.setItem('jwt', response.jwt)
    dispatch(userActions.setUserData(response.user))

    setAuthorized(true)
  }, [])

  if (!authorized) {
    return null
  }

  return (
    children
  )
}

export default () => {
  return (
    <WithAuthorization>
      <App />
    </WithAuthorization>
  )
}