import React from 'react'

import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  useSelector, useDispatch 
} from 'react-redux'

import { userActions } from '../redux/slices/user'

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
  border-bottom: 1px solid #eef0f6;
`

const Logo = styled.img`
  height: 40px;
`

const AuthButton = styled(Link)`
  padding: 0.5rem 1rem;
  background-color: #a061ff;
  color: white;
  text-decoration: none;
  border-radius: 20px;
`

const Header = () => {
  const dispatch = useDispatch()
  const { userData } = useSelector((state) => state.user)

  const handleLogout = () => {
    dispatch(userActions.logout())
  }

  return (
    <HeaderContainer>
      <Logo
        src="/logo.png"
        alt="Logo"
      />
      {userData ? (
        <AuthButton
          as="button"
          onClick={handleLogout}
        >Выйти</AuthButton>
      ) : (
        <AuthButton to="/auth/login">Войти</AuthButton>
      )}
    </HeaderContainer>
  )
}

export default Header