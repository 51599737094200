import {
  createSlice, createAsyncThunk 
} from '@reduxjs/toolkit'
import { GAMES } from 'src/api/games'

const initialState = {
  loading: false,
  myGames: [],
  currentGame: null,
  answers: {},
  videoShowed: false,
  gamesStates: {}
}

export const fetchMyGames = createAsyncThunk('games/fetchMyGames', async () => {
  const games = await GAMES.getMyGames()

  return games
})

const games = createSlice({
  name: 'games',
  initialState,
  reducers: {
    setCurrentGameState: (state, action) => {
      state.currentGame = {
        ...state.currentGame, ...action.payload 
      }
    },
    setAnswer: (state, action) => {
      const {
        questionId, answer 
      } = action.payload

      state.answers[questionId] = answer
    },
    clearCurrentGameState: state => {
      state.currentGame = null
      state.questions = null
    },
    clearAnswers: state => {
      state.answers = {}
    },
    clearGames: state => {
      state.myGames = initialState.myGames
      state.currentGame = initialState.currentGame
      state.answers = initialState.answers
    },
    toggleVideoStatus: state => {
      state.videoShowed = !state.videoShowed
    },

    setGameState: (state, action) => {
      state.gamesStates[action.payload.id] = action.payload
    }
  },
  extraReducers: {
    [fetchMyGames.pending]: state => {
      state.loading = true
    },
    [fetchMyGames.fulfilled]: (state, action) => {
      state.myGames = action.payload
      state.loading = false
    },
    [fetchMyGames.rejected]: state => {
      state.loading = false
    },
  },
})

const {
  actions: gamesActions, reducer: gamesReducer 
} = games

export { gamesActions, gamesReducer }
