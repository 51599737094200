import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { en } from './en'
import { ru } from './ru'

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en, },
      ru: { translation: ru, },
    },
    lng: 'ru', // Язык по умолчанию
    fallbackLng: 'en', // Запасной язык, если перевод отсутствует
    interpolation: { escapeValue: false }
  })

export default i18n

export { i18n }

// Вспомогательная функция для получения перевода
export const t = (key, options) => i18n.t(key, options)

// Функция для изменения языка
export const changeLanguage = (lang) => {
  i18n.changeLanguage(lang)
}

// Функция для получения текущего языка
export const getCurrentLanguage = () => i18n.language