import React from 'react'

import {
  useLocation, useNavigate 
} from 'react-router-dom'
import styled from 'styled-components'

// Импортируем изображения иконок
import i18n from 'src/localization'

import gamesIconActive from '../images/tabs/my-games-active.png'
import gamesIconInactive from '../images/tabs/my-games.png'
import categoriesIconActive from '../images/tabs/categories-active.png'
import categoriesIconInactive from '../images/tabs/categories.png'
import ratingIconActive from '../images/tabs/rating-active.png'
import ratingIconInactive from '../images/tabs/rating.png'

const TabBarContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #FFFFFF;
  border-top: 1px solid #E0E0E0;
  height: 56px;
  z-index: 1000;
`

const TabItem = styled.button`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 6px 0;
  cursor: pointer;
  outline: none;
`

const IconImage = styled.img`
  width: 24px;
  height: 24px;
  margin-bottom: 4px;
  object-fit: contain;
`

const TabLabel = styled.span`
  font-size: 10px;
  line-height: 12px;
  color: ${props => props.active ? '#A061FF' : '#9A9A9A'};
`

const getIcon = (key, active) => {
  switch (key) {
    case 'games':
      return active ? gamesIconActive : gamesIconInactive
    case 'categories':
      return active ? categoriesIconActive : categoriesIconInactive
    case 'rating':
      return active ? ratingIconActive : ratingIconInactive
    // case 'profile':
    //   return active ? profileIconActive : profileIconInactive
    default:
      return gamesIconInactive
  }
}

const TabBar = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const tabs = [
    {
      key: 'games', path: '/'
    },
    {
      key: 'categories', path: '/categories'
    },
    {
      key: 'rating', path: '/rating'
    },
    // {
    //   key: 'profile', path: '/profile', label: i18n.t('tabbar.profile') 
    // },
  ]

  const handleTabClick = (path) => {
    navigate(path)
  }

  console.log(location.pathname)

  return (
    <TabBarContainer>
      {tabs.map((tab) => {
        const isActive = location.pathname === tab.path || ((tab.path.length > 3) && location.pathname.startsWith(tab.path))
        return (
          <TabItem
            key={tab.key}
            onClick={() => handleTabClick(tab.path)}
          >
            <IconImage
              src={getIcon(tab.key, isActive)}
              alt={tab.label}
            />
          </TabItem>
        )
      })}
    </TabBarContainer>
  )
}

export default TabBar