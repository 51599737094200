import React, {
  useEffect, useRef, useState 
} from 'react'

import styled from 'styled-components'
import {
  useDispatch, useSelector 
} from 'react-redux'
import {
  useNavigate, useParams, useLocation 
} from 'react-router-dom'
import { GAMES } from 'src/api/games'
import { gamesActions } from 'src/redux/slices/games'
import i18n from 'src/localization'

import crystalImg from './../../images/end-game.png'
import { RoundLayout } from './RoundLayout'
import { Question } from './Question'
import { AnswerOptions } from './AnswerOptions'
import { AnswerText } from './AnswerText'
import { RoundPreview } from './RoundPreview'

const Container = styled.div`
  margin-top: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 56px 24px 39px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  background-color: #F5F8FA;
  padding-top: 24px;
  padding-bottom: 50px;
`

const GameFinishedText = styled.h2`
  font-size: 21px;
  color: #4A6495;
  font-family: 'Rubik-Medium', sans-serif;
`

const Image = styled.img`
  margin-top: 31px;
  width: 176px;
  height: 176px;
`

const PointsText = styled.p`
  margin-top: ${props => props.top ? '46px' : props.middle ? '8px' : '8px'};
  font-family: ${props => props.middle ? 'Rubik-Bold' : 'Rubik-Medium'}, sans-serif;
  color: ${props => props.middle ? '#62B67A' : '#D2D5DD'};
  font-size: ${props => props.middle ? '40px' : '16px'};
`

const Button = styled.button`
  margin-top: 20px;
  height: 48px;
  padding: 0 64px;
  border: none;
  border-radius: 30px;
  font-family: 'Rubik-Bold', sans-serif;
  font-size: 11px;
  color: white;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? 0.5 : 1};
  background-color: #FBC399;
  color: #B67C62;
  box-shadow: 0 2px 4px #B67C62;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
`

const InfoButton = styled.button`
  width: 40px;
  height: 35px;
  border-radius: 25px;
  border: 1px solid #EEF0F6;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
`

const InfoButtonText = styled.span`
  font-size: 16px;
  color: #FCBD11;
  font-family: 'Rubik-Bold', sans-serif;
`

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 300px;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 23px;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
`

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`

const ModalTitle = styled.h3`
  font-size: 21px;
  color: #4A6495;
  font-family: 'Rubik-Medium', sans-serif;
`

const ModalDescription = styled.p`
  margin-top: 20px;
  font-size: 14px;
  color: #D2D5DD;
  font-family: 'Rubik-Medium', sans-serif;
  padding: 0 10px;
  max-height: 400px;
  overflow-y: auto;
`

export const Round = () => {
  const [game, setGame] = useState({})
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [isGameFinished, setIsGameFinished] = useState(false)
  const [infoModalOpened, toggleInfoModal] = useState(false)
  const [infoModalToggleVisible, toggleInfoModalToggleVisibility] = useState(false)

  const { id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const gamesStates = useSelector(state => state.games.gamesStates)
  const gameState = gamesStates[id] || {}
  const user = useSelector(state => state.user.userData)

  const timeoutRef = useRef(null)

  useEffect(() => {
    const loadGameInfo = async () => {
      const response = await GAMES.getGameById(id)

      if (gameState.finished) {
        setIsGameFinished(true)
      } else {
        setIsGameFinished(false)
        setCurrentQuestionIndex(gameState.currentQuestionIndex || 0)
      }

      setGame({
        ...response.data.attributes, id 
      })
    }

    loadGameInfo()
  }, [id])

  const questions = game.questions?.data

  if (!questions || !questions.length || Number(id) !== Number(game.id)) {
    return <RoundPreview />
  }

  const currentQuestion = questions[currentQuestionIndex]?.attributes
  const answer = currentQuestion.answer
  const questionsCount = questions.length

  const handleSetAnswer = async (answer) => {
    const newData = { rightAnswersCount: answer.isCorrect ? (gameState.rightAnswersCount || 0) + 1 : (gameState.rightAnswersCount || 0) }

    if (currentQuestionIndex !== questions.length - 1) {
      newData.currentQuestionIndex = currentQuestionIndex + 1
      toggleInfoModalToggleVisibility(true)

      timeoutRef.current = setTimeout(() => {
        toggleInfoModalToggleVisibility(false)
        setCurrentQuestionIndex(currentQuestionIndex + 1)
      }, 3000)
    } else {
      newData.finished = true

      GAMES.sendResults({
        user: user.id,
        game: id,
        correctAnswers: gameState.rightAnswersCount
      })

      toggleInfoModalToggleVisibility(true)

      timeoutRef.current = setTimeout(() => {
        toggleInfoModalToggleVisibility(false)
        setIsGameFinished(true)
      }, 3000)
    }

    dispatch(gamesActions.setGameState({
      id, ...newData 
    }))
  }

  const handleExitGame = () => {
    navigate('/')
  }

  const handleOpenInfo = () => {
    clearTimeout(timeoutRef.current)
    toggleInfoModal(true)
  }

  const handleNextQuestion = () => {
    toggleInfoModal(false)
    toggleInfoModalToggleVisibility(false)

    if (currentQuestionIndex === questions.length - 1) {
      setIsGameFinished(true)
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    }
  }

  return (
    <>
      {/* {infoModalToggleVisible && !!currentQuestion.explanation && (
        <InfoButton onClick={handleOpenInfo}>
          <InfoButtonText>?</InfoButtonText>
        </InfoButton>
      )} */}

      <InfoButton onClick={handleOpenInfo}>
        <InfoButtonText>?</InfoButtonText>
      </InfoButton>

      {!isGameFinished && (
        <RoundLayout
          questionNumber={currentQuestionIndex + 1}
          questionsCount={questionsCount}
        >
          <Question 
            text={currentQuestion.question}
            image={currentQuestion?.image?.data}
          />

          {!!currentQuestion.option.length ? (
            <AnswerOptions
              options={currentQuestion.option}
              onSetAnswer={handleSetAnswer}
              answer={answer}
              infoModalOpened={infoModalOpened}
            />
          ) : (
            <AnswerText
              key={currentQuestion.question}
              onSetAnswer={handleSetAnswer}
              correctAnswer={answer}
              infoModalOpened={infoModalOpened}
            />
          )}
        </RoundLayout>
      )}

      {isGameFinished && (
        <Container>
          <GameFinishedText>{i18n.t('gameScreen_end')}</GameFinishedText>
          <Image
            src={crystalImg}
            alt="Game finished"
          />
          <PointsText top>{i18n.t('gameScreen_scored')}</PointsText>
          <PointsText middle>{gameState.rightAnswersCount}</PointsText>
          <PointsText>{i18n.t('gameScreen_points')}</PointsText>
          <Button onClick={handleExitGame}>{i18n.t('gameScreen_next')}</Button>
        </Container>
      )}

      {infoModalOpened && (
        <>
          <ModalOverlay />
          <Modal>
            <ModalTitle>{i18n.t('gameScreen_correct')}</ModalTitle>
            <ModalDescription>{currentQuestion.explanation}</ModalDescription>
            <Button onClick={handleNextQuestion}>{i18n.t('gameScreen_next')}</Button>
          </Modal>
        </>
      )}
    </>
  )
}