import React, {
  useEffect, useState 
} from 'react'

import classNames from 'classnames'
import styled from 'styled-components'

const Container = styled.div`
  margin-top: 16px;
  width: 100%;
  position: relative;
`

const Input = styled.input`
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border: 1px solid #e6e9f1;
  border-radius: 20px;
  font-family: 'Rubik-Regular', sans-serif;
  font-size: 14px;
`

const Button = styled.button`
  margin-bottom: 10px;
  height: 48px;
  width: 100%;
  border: none;
  border-radius: 30px;
  font-family: 'Rubik-Bold', sans-serif;
  font-size: 11px;
  color: white;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? 0.5 : 1};
  background-color: #FBC399;
  color: #B67C62;
  box-shadow: 0 2px 4px #B67C62;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  text-align: center;

  &.green {
    background-color: #90C67B;
    color: white;
  }

  &.red {
    background-color: #FF5550;
    color: white;
  }

  &.disabled {
    background-color: #EAEBF0;
    color: #A7ABB5;
  }

  @media (min-width: 1200px) {
    font-size: 16px;
  }
`

export const AnswerText = ({
  onSetAnswer, correctAnswer, disabled = false, infoModalOpened 
}) => {
  const [value, setValue] = useState('')
  const [answer, setAnswer] = useState(null)

  const handleSetAnswer = () => {
    const answer = {
      isCorrect: value.toLowerCase() === correctAnswer.toLowerCase(),
      answer: value
    }

    setAnswer(answer)

    setTimeout(() => {
      setAnswer(null)
    }, 3000)

    onSetAnswer(answer)
  }

  useEffect(() => {
    if (infoModalOpened) {
      setAnswer(null)
    }
  }, [infoModalOpened])

  return (
    <Container>
      <Input
        placeholder="Ответ"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={disabled}
      />

      <Button
        onClick={handleSetAnswer}
        readOnly={!!answer}
        className={classNames([!answer ? 'orange' : answer.isCorrect ? 'green' : 'red'], { disabled: !!answer })}
      >
        Отправить
      </Button>
    </Container>
  )
}