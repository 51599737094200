import { combineReducers } from 'redux'

import { appReducer } from './slices/app'
import { userRegistrationReducer } from './slices/userRegistration'
import { userReducer } from './slices/user'
import { gamesReducer } from './slices/games'
import { categoriesReducer } from './slices/categories'

export const rootReducer = combineReducers({
  app: appReducer,
  userRegistration: userRegistrationReducer,
  user: userReducer,
  games: gamesReducer,
  categories: categoriesReducer
})

