import { Http } from './Http'

export const USER = {
  confirmEmail: data => Http.get('/api/auth/email-confirmation', data),
  register: data => Http.post('/api/auth/local/register', data),
  auth: data => Http.post('/api/auth/local', data),
  deleteAccount: userId => Http.delete(`/api/users/${userId}`),
  askForResetPasswordCode: data => Http.post('/api/auth/forgot-password', data),
  resetPassword: data => Http.post('/api/auth/reset-password', data),
  googleAuth: (token) => Http.get(`/api/auth/google/callback?access_token=${token}`),
  sendFCMToken: (token) => Http.post('/api/device-ids', { deviceId: token }),
  sendAppleToken: (data) => Http.post('/api/apple-auth/token', data),
  updateUser: (userId, data) =>  Http.put(`/api/users/${userId}`, data),
  tgAuth: (data) => Http.post('/api/auth/user', data),
  authUser: (data) => Http.post('/api/auth/telegram', data),
}
