export const ru = {
  gamesScreen_title: 'Список игр',
  gamesScreen_description: 'Игры, в которые можно сыграть',
  gamesScreen_all: 'Все',
  gamesScreen_popular: 'Популярное',
  gamesScreen_new: 'Новое',

  gameCard_play: 'Играть',
  gameCard_questions: 'вопросов',
  gameCard_completed: 'Игра пройдена',
  gameCard_from: 'из',
  gameCard_points: 'баллов',

  gameScreen_question: 'Вопрос',
  gameScreen_correct: 'Правильный ответ',
  gameScreen_next: 'Дальше',
  gameScreen_end: 'Конец игры',
  gameScreen_scored: 'В этой игре вы набрали',
  gameScreen_points: 'баллов',

  ratingScreen_players: 'Игроки',
  ratingScreen_points: 'Баллы',
  ratingScreen_emptyTitle: 'Рейтинг команд',
  ratingScreen_emptyDescription: 'Рейтинг будет доступен в приложении как только наберется достаточная статистика по играм.',

  menu_unauthorized: 'Вход не выполнен',
  menu_games: 'Ко всем играм',
  menu_aboutApp: 'О приложении',
  menu_removeAccount: 'Удалить аккаунт',
  menu_logout: 'Выйти из профиля',
  menu_removingTitle: 'Удаление аккаунта',
  menu_removingDescription: 'Вы уверены, что хотите удалить аккаунт?',
  menu_removingCancel: 'Отмена',
  menu_removingRemove: 'Удалить',

  categoriesScreen_title: 'Категории',

  helloScreen_start: 'Начать!',
  helloScreen_ownedBy: 'Игра Квиз принадлeжит',

  aboutAppScreen_email: 'Почта:',

  header_login: 'Войти',

  loginScreen_title: 'Насколько мы знакомы?',
  loginScreen_description: 'Выберите из двух вариантов',
  loginScreen_option1: 'Бывалый',
  loginScreen_option2: 'Новенький',

  loginScreen_letsCheck: 'Давайте проверим?',
  loginScreen_wrong: 'Не торопитесь :)',
  loginScreen_error: 'Ошибка',
  loginScreen_sent: 'Мы отправили',
  loginScreen_emailError: 'Необходимо ввести ваш действующий email',
  loginScreen_enterEmail: 'Введите email',
  loginScreen_signIn: 'Войти',
  loginScreen_enterPassword: 'Введите пароль.',
  loginScreen_forgot: 'Забыли?',
  loginScreen_restore: 'Восстановить',
  loginScreen_check: 'Проверим',
  loginScreen_passwordError: 'Введите пароль длинной не менее 6 символов',
  loginScreen_wrongPassword: 'Неверный пароль',
  loginScreen_welcomeTitle: 'Приветствуем!',
  loginScreen_welcomeDescription: 'Приятной игры, а мы проверим ваши познания. Получайте баллы за игру и соревнуйтесь с другими игроками',
  loginScreen_welcomeBtn: 'Взаимно',
  loginScreen_enterEmailForRestore: 'Введите email. Отправим на него код для восстановления пароля.',
  loginScreen_sendEmail: 'Отправить письмо',
  loginScreen_codeSent: 'Мы отправили',
  loginScreen_codeDescription: 'Письмо отправлено, проверьте почтовый ящик. Введите 6-значный код.',
  loginScreen_wrongCode: 'Неверный код',
  loginScreen_createPassword: 'Придумайте пароль',
  loginScreen_next: 'Продолжить',

  loginScreen_step: 'Шаг',
  loginScreen_nameStepTitle: 'Давайте знакомиться?',
  loginScreen_nameStepEnterName: 'Введите Ваше имя',
  loginScreen_nameStepName: 'ИМЯ',
  loginScreen_nicknameDescription: 'Теперь немного креативности, никнейм может быть весёлым :)',
  loginScreen_nicknameExists: 'Игрок с таким никнеймом уже существует',
  loginScreen_enterNickname: 'Введите никнейм',
  loginScreen_nickname: 'Никнейм',
  loginScreen_registrationEmail: 'Укажите ваш email-адрес. Мы отправим код подтверждения для завершения регистрации',
  loginScreen_checkPassword: 'Проверьте правильность введенного пароля',
  loginScreen_weSent: 'Мы отправили',
  loginScreen_enterCode: 'Введите 6-значный код для завершения регистрации.',
  loginScreen_codeNotReceived: 'Не пришел код?',
  loginScreen_checkSpam: 'Проверьте раздел спам',
  loginScreen_orResend: 'или отправьте код',
  loginScreen_again: 'повторно',
  loginScreen_registrationSuccessTitle: 'Вы справились!',
  loginScreen_registrationSuccessDescription: 'Приятной игры, а мы проверим ваши познания. Получайте баллы за игру и соревнуйтесь с другими игроками',
  loginScreen_registrationSuccessBtn: 'Отлично',
  loginScreen_auth_with_social: 'Или авторизируйтейсь через один из сервисов'
}