import queryString from 'query-string'

import { HOST } from '../constants'

const headers = {
  'Access-Control-Allow-Origin': '*',
  Accept: 'application/json',
  'Accept-Language': 'ru-RU'
}

export const Http = {
  HOST,

  get: async (url, data) => {
    let path = Http.HOST + url

    if (data && Object.keys(data).length) {
      path += `?${queryString.stringify(data)}`
    }

    const config = {
      headers: { ...headers, },
      method: 'get',
    }

    const jwt = await localStorage.getItem('jwt')

    if (jwt) {
      config.headers.Authorization = `Bearer ${jwt}`
    }

    console.log(path)

    // eslint-disable-next-line
    const response = await fetch(path, config)

    if (response.status >= 200 && response.status < 300) {
      const json = await response.json()

      return json
    }

    const error = await response.json()

    throw error
  },

  post: async (url, data) => {
    const path = Http.HOST + url

    const config = {
      headers: { ...headers, },
      method: 'post',
    }

    if (data) {
      config.headers['Content-Type'] = 'application/json'
      config.body = JSON.stringify(data)
    }

    const jwt = await localStorage.getItem('jwt')

    if (jwt) {
      config.headers.Authorization = `Bearer ${jwt}`
    }

    // eslint-disable-next-line
    const response = await fetch(path, config)

    if (response.status >= 200 && response.status < 300) {
      const json = await response.json()

      return json
    }

    const error = await response.json()

    throw error
  },

  put: async (url, data) => {
    const path = Http.HOST + url

    const config = {
      headers: { ...headers, },
      method: 'put',
    }

    if (data) {
      config.headers['Content-Type'] = 'application/json'
      config.body = JSON.stringify(data)
    }

    const jwt = await localStorage.getItem('jwt')

    if (jwt) {
      config.headers.Authorization = `Bearer ${jwt}`
    }

    // eslint-disable-next-line
    const response = await fetch(path, config)

    if (response.status >= 200 && response.status < 300) {
      const json = await response.json()

      return json
    }

    const error = await response.json()

    throw error
  },

  delete: async (url, data = {}) => {
    const path = Http.HOST + url

    const config = {
      headers: { ...headers, },
      method: 'delete',
    }

    if (data) {
      config.headers['Content-Type'] = 'application/json'
      config.body = JSON.stringify(data)
    }

    const jwt = await localStorage.getItem('jwt')

    if (jwt) {
      config.headers.Authorization = `Bearer ${jwt}`
    }

    // eslint-disable-next-line
    const response = await fetch(path, config)

    if (response.status >= 200 && response.status < 300) {
      const json = await response.json()

      return json
    }

    const error = await response.json()

    throw error
  },
}
